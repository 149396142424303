.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

body{
  overflow-x: hidden;
  background-color: #f5f7fe !important;
}

.cursor {
  cursor: pointer;
}

.font-wt-800{
  font-weight: 800 !important;
}

.font-wt-700{
  font-weight: 700 !important;
}

.font-wt-600{
  font-weight: 600 !important;
}

.w-30{
  width: 30% !important;
}

.w-35{
  width: 35% !important;
}

.w-40{
  width: 40% !important;
}

.w-60{
  width: 60% !important;
}

.w-65{
  width: 65% !important;
}

.w-85{
  width: 85% !important;
}

.w-95{
  width: 95% !important;
}

.w-90{
  width: 90% !important;
}

.w-15{
  width: 15% !important;
}

.font-size-10 {
  font-size: 10px !important;
}

.font-size-11 {
  font-size: 11px !important;
}

.font-size-12 {
  font-size: 12px !important;
}

.font-size-13 {
  font-size: 13px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-15 {
  font-size: 15px !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-18 {
  font-size: 18px !important;
}

.text-color1 {
  color: #1B94B7 !important;
}

.loading-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.67);
  top: 0;
  left: 0;
  z-index: 40;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-overlay span {
  width: 80px;
  height: 80px;
}

.loading-overlay span img {
  width: 100%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
